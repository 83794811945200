.App {
  text-align: center;
  
}

.myNav:hover {
  background-color: purple;
  

  
}
body {
  font-family: 'Rubik, sans-serif';
 
}
.bg-image {
  /* The image used */
  background-image: url("./School_logo.jpg");

  /* Add the blur effect */
  filter: blur(15px);
  -webkit-filter: blur(15px);
  width: 100%;
  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-image-2 {
  
  background-image: url("./build1.jpg");

  
  filter: blur(6px);
  -webkit-filter: blur(6px);
  width: 100%;

  height: 100vh;

  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Position text in the middle of the page/image */
.bg-text {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: black;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  border-style: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  
  /* padding: 200px; */
  text-align: center;
}

.bg-text-2 {
  /* top: 10%; */
  height: 100vh;
  width: 100%;
  

}
.bg-text-3 {
  position: relative;
  top: 100px;
  left: 0%;
  transform: translate(0%, 0%);
  margin-top: 200px;
}
.homeFooter {
  background-color: black;
  color: white;
  padding: 20px;
}

.zoom-in-out-box {
  /* margin: 24px;
  width: 50px;
  height: 50px;
  background: #f50057; */
  animation: zoom-in-zoom-out 3s ease ;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* .classroom {
  background-image: url('');
    background-size: "cover";
    background-position: "center";
    background-repeat: 'no-repeat';
    height: "100vh";
    filter: 'blur(4px)';
    -Webkit-filter: 'blur(2px)';
} */
.example {
  cursor: pointer;
  height: 300px;
  position: relative;
  color: antiquewhite;
  background: #111;
  border-radius: 10px;
  overflow: hidden;
  /* width: 300px; */
  text-align: center;
}
.example .fadedbox {
  
  position: absolute;
  top: 0;
  left: 0;
 
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  opacity: 0;
  /* width: 200px; */
  height: 100px;
  
}
.example:hover .fadedbox {
  opacity: 0.8;
}
.example .text {
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  transform: translateY(150px);
  -webkit-transform: translateY(150px);
  font-size: 1.5em;
  text-align:  center;
  text-transform: uppercase;
}
.example .title {
  
  opacity: 1;
  transition-delay: 0.2s;
  transition-duration: 0.3s;
  
}
.example:hover .title,
.example:focus .title {
  opacity: 0;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}





.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111;
  padding-top: 20px;
}
.sidebar {
  width: 250px;
  height: 100%;
  background-color: #333;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidebar a {
  padding: 16px;
  text-decoration: none;
  font-size: 18px;
  color: #818181;
  display: block;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.admin-container {
  display: flex;
}

.admin-content {
  flex: 1;
  padding: 20px;
}

.sidebar h2 {
  color: white;
  text-align: center;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 8px;
  text-align: center;
  color: white;
  cursor: pointer;
}

.sidebar li a {
  color: white;
  text-decoration: none;
}

.sidebar li:hover {
  background-color: #555;
}

/* App.css */
.app-container {
  display: flex;
}

.main-content {
  margin-left: 250px; /* Adjust based on the sidebar width */
  padding: 20px;
  
}

/* .home-text {
  font-size: "65px"; 
  font-family: 'Montserrat, sans-serif'; 
  font-weight: "bold"; 
  visibility: visible;
} */